import { FormPlugin, paymentMappingToRadioOptions, getCurrencyByKey } from '@wix/forms-common'
import _ from 'lodash'
import { FIELDS } from '../../constants/roles'
import { siteStore } from '../stores/site-store'

const addCustomAmountValidation = ({ $w, $form }) => {
  const $customAmountField = $w(`@${FIELDS.ROLE_FIELD_CUSTOM_AMOUNT}`)
  const currency = _.get(siteStore.wixApi, 'site.currency')
  const currencySymbol = _.get(getCurrencyByKey(currency), 'symbol')

  const paymentPluginData = _.find(
    _.get($form, 'connectionConfig.plugins'),
    (plugin) => plugin.id === FormPlugin.PAYMENT_FORM,
  )

  _.forEach($customAmountField, ($field: WixCodeField) => {
    if ($field.prefix && $field.prefix !== currencySymbol) {
      $field.prefix = currencySymbol
    }
    const productId = _.get($field, 'connectionConfig.productId')
    const productData = _.get(paymentPluginData, `payload.items[${productId}]`)

    const defaultPriceValue = _.toNumber(_.get(productData, 'price'))

    if (defaultPriceValue) {
      $field.value = defaultPriceValue.toString()
    }

    $field.onCustomValidation((value, reject) => {
      if (!$field.required && _.isEmpty(value)) {
        return
      }

      const number = _.toNumber(value)

      if (_.isNaN(number)) {
        reject(siteStore.t('paymentField.invalidFormat'))
      } else {
        const minValue = _.toNumber(_.get(productData, 'min') || 0)
        const maxValue = _.toNumber(_.get(productData, 'max') || Number.MAX_SAFE_INTEGER)

        if (number < minValue || number > maxValue) {
          reject(siteStore.t('paymentField.outOfRange'))
        }
      }
    })
  })
}

const addItemsListBehavior = ({ $w }) => {
  const $listItemsField = $w(`@${FIELDS.ROLE_FIELD_ITEMS_LIST}`)
  const currency = _.get(siteStore.wixApi, 'site.currency')

  if (currency) {
    _.forEach($listItemsField, ($field) => {
      const paymentMapping = _.get($field, 'connectionConfig.paymentItemsMapping')
      if (paymentMapping) {
        $field.options = paymentMappingToRadioOptions(paymentMapping, currency, {
          t: siteStore.t.bind(siteStore),
        })
      }
    })
  }
}

export const registerPaymentFieldsIfExists = (payload) => {
  addCustomAmountValidation(payload)
  addItemsListBehavior(payload)
}
